<template>
	<div>
		<v-avatar size="160">
			<v-img :src="company.imgURL" />
		</v-avatar>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'CompanyLogo',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		...mapGetters({
			company: 'companies/company',
		}),
	},
}
</script>
